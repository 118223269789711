<template>
  <div>
    <ManageLevelCourse />
  </div>
</template>
<script>
import ManageLevelCourse from "@/components/LevelCourse/ManageLevelCourse";
export default {
  components: {
    ManageLevelCourse,
  },
  created() {},
};
</script>

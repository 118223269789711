<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          ระดับการศึกษา
        </span>
      </v-col>
      <!-- <v-col cols="12">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="#2AB3A3" dark
              >เพิ่มระดับการศึกษา</v-btn
            >
          </v-col>
        </v-row>
      </v-col> -->
      <v-card class="pa-3" width="100%" color="#f5f5f5" flat>
        <v-card class="pa-3" width="100%">
          <v-col cols="12">
            <v-card
              ><v-data-table
                :headers="headersBrand"
                :items="items"
                :search="search"
                disable-pagination
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                  <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                  <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-card>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      // createdAt: (...)
      // facualty: Object
      // facualtyId: 1
      // id: (...)
      // name: (...)
      // sortindex: 1
      // updatedAt: (...)
      headersBrand: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อ (ไทย)", value: "name_th", align: "start" },
        { text: "ชื่อ (อังกฤษ)", value: "name_en", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAllbanner();
    document.title = "ระดับการศึกษา - GSCM Management System";
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
      //   val,
      //   auth
      // );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbanner() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/levels`,
        auth
      );
      console.log("getAllbannerpe", response.data.data);
      this.items = response.data.data;
      this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      console.log("itemBefore", this.items);
      console.log("itemAfter", this.items);
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/levels/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbanner();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateLevelCourse");
    },
    UpdateNews(val) {
      localStorage.setItem("levelsData", Encode.encode(val));
      this.$router.push("EditLevelCourse");
    },
    ViewNews(val) {
      localStorage.setItem("levelsData", Encode.encode(val));
      this.$router.push("ViewLevelCourse");
    },
  },
};
</script>